import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Map from '../../components/Map';
import Team from '../../components/people';

const Contact = ({ data }) => {
  const team = data.allPeopleJson.edges.map(item => item.node);
  return (
    <Layout bodyClass="page-contact">
      <SEO title="Contact" />
        <div className="intro pb-4">
          <div className="container">
            <h1>Contact</h1>
            <p>
              {"Our FinTech space ship has landed at Launchpad — the hottest startup area of Singapore. Don't hesitate to drop by our beautiful office for a coffee and a chat about your future in FinTech."}
            </p>
          </div>
        </div>
      <div className="team col-12 pt-4 pb-4 text-center">
        <h1>
          Team
        </h1>
      </div>
      <Team people={ team } />
      
      <div className="w-100">
        <Map />
      </div>
    </Layout>
  );
};

Contact.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    allPeopleJson(filter: {teams: {in: ["labs"]}}) {
      edges {
        node {
          id
          name
          image
          jobtitle
          company {
            name
            url
          }
          description
          linkedinurl
          email
        }
      }
    }
  }
`;

export default Contact;
