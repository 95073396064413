import React from 'react';
import GoogleMapReact from 'google-map-react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import logo from '../images/logo.png';


const MapMarker = () => (
  <Tooltip
    html={(
      <div>
        79 Ayer Rajah Crescent
        <br/>
        #05-08 Block 79
        <br/>
        Singapore 139955
      </div>
    )}
    trigger="mouseenter"
    theme="dark"
  >
    <img alt="BAASIS LABS"
         src={logo}
         style={{ width: 30 }}
    />
  </Tooltip>
);

const ContactMap = () => {
  const defaultProps = {
    center: [1.298208, 103.787293],
    zoom: 17,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '75vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCyWMBkJB9uG-4m1af6aCJp67J9ZcJA0cs' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapMarker
          lat={1.298208}
          lng={103.787293}
        />
      </GoogleMapReact>
    </div>
  );
};

export default ContactMap;
